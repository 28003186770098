<template>
  <v-container class="mt-8">
    <v-row justify="center">
      <v-col md="8">
        <v-card flat outlined>
          <v-row justify="start">
            <v-col md="4" class="">
              <v-text-field
                style="height: 20px !important"
                dense
                class="my-3 mx-5"
                outlined
                v-model="rule_name"
                :label="$t('name')"
              > 
              </v-text-field>
            </v-col>
            <v-col class="pb-0" md="2"> </v-col>
          </v-row>
          <v-row justify="start" class="ma-0 mt-2">
            <v-col md="2" class="pa-0"></v-col>
            <v-col
              class="pa-0"
              v-for="(item, index) in type"
              :key="index"
              md="2"
            >
              <v-radio-group v-model="selectedItem" style="height: 30px">
                <v-radio :label="item" :value="item" color="success"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row justify="start" class="ma-0">
            <v-col md="2" class="px-0"></v-col>
            <v-col
              v-for="(item, index) in classifications"
              :key="index"
              class="pa-0 ma-0"
              md="3"
            >
              <v-radio-group v-model="selectedClass">
                <v-radio :label="item" :value="item" color="success"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="2" align="center" class="mt-2 mb-5">
            {{ $t("hr.employee.rules.value") }}:
            </v-col>
            <v-col md="4">
              <v-text-field
                style="height: 20px !important"
                dense
                outlined
                v-model="value"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <h3 class="mt-2" v-if="selectedClass == 1">const</h3>
              <h3 class="mt-2" v-if="selectedClass == 'عدد الأيام'">
                {{ $t("initiate.Day") }}
              </h3>
              <h3 class="mt-2" v-if="selectedClass == 'نسبة من الراتب'">%</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="2" align="center" class="mt-2 mb-5">
             {{ $t('hr.employee.check.account') }}  
            </v-col>
            <v-col md="4">
              <v-autocomplete
              dense
              outlined
              :items="accountItems"
              :loading="accountLoading"
              color="#757575"
              item-text="name"
              item-value="id"
              v-model="ContractSelect"
              
              @keypress="fetchAutoCompleteAccount($event)"
            >
            </v-autocomplete>
            </v-col>
          </v-row>
        </v-card>
        <v-row justify="center" class="mt-6">
          <v-col cols="2">
            <v-btn color="primary" class="save-button" @click="submit">{{
              $t("save")
            }}</v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn @click="cancel" color="error" class="cancel-button">{{
              $t("cancel")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import axios from "axios";
import i18n from '@/i18n';
import debounce from "../../../../../helpers/debounce";

export default {
 async created() {
   
    if (this.$route.params.id != "CreateNewRule") {
      try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete?query=1"
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } 
      axios.get(`/hr/rule/${this.$route.params.id}`).then((res) => {
        this.rule_name = res.data.data.name;
        if (res.data.data.changing_type == "decrease") {
          this.selectedItem = "خصم";
        } else {
          this.selectedItem = "إضافة";
        }
        if (res.data.data.rule_type == "hours") {
          this.selectedClass = "عدد الأيام";
        }
        if (res.data.data.rule_type == "value") {
          this.selectedClass = "قيمة ثابتة";
        }
        if (res.data.data.rule_type == "percent") {
          this.selectedClass = "نسبة من الراتب";
        }
        this.value = res.data.data.value;
        this.ContractSelect = res.data.data.account.id;
      });
    }
  },
  data() {
    return {
      accountItems: [],
      accountLoading: false,
      rule_name: "",
      selectedItem: null,
      selectedClass: "",
      type: [i18n.t('add'), i18n.t('rebate')],
      classifications: [i18n.t('percent'), i18n.t('constValue'), i18n.t('numberOfDays')],
      value: null,
      rule_type: "",
      selected_change: "",
      SalaryAccount: [],
      ContractSelect: "",
    };
  },
  methods: {
    async fetchAutoCompleteAccount(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountLoading = false;
        }
      }, 500)();
    },
    
    submit() {
      if (this.selectedClass == "نسبة من الراتب") {
        this.rule_type = "percent ";
      }
      if (this.selectedClass == "قيمة ثابتة") {
        this.rule_type = "value";
      }
      if (this.selectedClass == "عدد الأيام") {
        this.rule_type = "hours";
      }
      if (this.selectedItem == "إضافة") {
        this.selected_change = "increase";
      } else {
        this.selected_change = "decrease";
      }
      
      if (this.$route.params.id == "CreateNewRule") {
        axios
          .post("/hr/rule", {
            name: this.rule_name,
            changing_type: this.selected_change,
            rule_type: this.rule_type,
            value: this.value,
            account_id: this.ContractSelect,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$router.go(-1);
              (this.name = ""),
                (this.value = ""),
                (this.selectedClass = ""),
                (this.selectedItem = ""),
                this.$Notifications(
                  "تمت اضافة قاعدة جديدة",
                  { rtl: true, timeout: 2500 },
                  "success"
                );
            }
          });
      } else {
        axios
          .put(`/hr/rule/${this.$route.params.id}`, {
            name: this.rule_name,
            changing_type: this.selected_change,
            rule_type: this.rule_type,
            value: this.value,
            account_id: this.ContractSelect,
          })
          .then((res) => {
            this.$router.go(-1);
            if (res.status == 200) {
              console.log(res);
              this.$Notifications(
                "تم تعديل قاعدة جديدة",
                { rtl: true, timeout: 2500 },
                "success"
              );
            }
          });
      }
    },
    cancel() {
      this.$router.push("/HR/initiate");
    },
  },
};
</script>